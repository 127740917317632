@import-normalize;

audio, video {
  display: inline-block;
}
img {
  border-style: none;
}

body {
    background: #ff9966;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ff5e62, #ff9966);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ff5e62, #ff9966); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    margin: 0;
}

* {
    font-family: 'Open Sans', sans-serif;
    color: #444;
}


hr {
    background-color: #ddd;
    height: 1px;
    border: 0;
}

header.App-header {
    text-align: center;
    margin: 80px auto;
    font-size: 1.5rem;
    max-width: 800px;
}
header.App-header h1 {
    color: white;
}

main {
    width: calc(100% - 80px);
    display: flex;
    max-width: 1200px;
    margin: 0 auto 80px;
    background: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 3px 6px 0px #ccc;
}

main > div {
    flex: 1;
}
@media screen and (max-width: 767px){
    main {
        flex-direction: column;
    }
}

@media screen and (min-width: 767px){
    .html-generated-container {
        margin-left: 20px;
    }
}

footer {
    background: #1e1e1e;
    color: #e9e9e9;
    text-align: center;
    padding: 20px;
    font-size: 0.9rem;
}
footer span,
footer a {
    color: #e9e9e9;
}

select {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.select-input {
    display: flex;
    justify-content: space-between;
}
.simple-input {
    display: flex;
}
.simple-input > *,
.select-input > * {
    flex: 1;
    margin: 5px 0 10px;
}
.simple-input input {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.html-generated-container > span {
    font-size: 0.85rem;
    color: #777;
}

#html-generated {
    overflow-x: auto;
}

.copy-to-clipboard-btn {
    border: 1px solid #1e1e1e;
    color: #1e1e1e;
    background: transparent;
    padding: 4px 8px;
    font-size: 0.75rem;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s all;
}
.copy-to-clipboard-btn:hover {
    background-color: #1e1e1e;
    color: #eee;
}